@import "../../@theme/styles/variables";

.illustration-svg {
    width: 100%;
    height: auto;
}

svg {
    * {
        transform-origin: 50% 50%;
    }
}

@mixin genSvgClasses($name, $value) {
    .fill-#{$name} {
        fill: $value;
    }
    .fill-#{$name}-darker {
        fill: darken($value, 5%);
    }
    .stroke-#{$name} {
        stroke: $value;
    }
    .stroke-#{$name}-darker {
        stroke: darken($value, 5%);
    }
}

@each $name, $value in $colors {
    @include genSvgClasses($name, $value);
}

@each $name, $value in $theme-colors {
    @include genSvgClasses($name, $value);
}
